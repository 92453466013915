import React from "react"
import loadable from "@loadable/component";
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import { StyledMarginWrapper } from "../../common-styles"
import { 
    CATCH_UP_SECTION_TITLE, 
    CATCH_UP_SECTION_DESCRIPTION,
    CONTACT_US_BUTTON_TEXT
} from "../../constants";
import { StyledContactUsButton } from "../../styled";

const CrawlableSecondCatchUpSection = loadable(() => import("@common/CommonSections/CrawlableSecondCatchUpSection"));

const CatchUpSection = () => {
  return (
    <StyledMarginWrapper>
      <CrawlableSecondCatchUpSection
        title={CATCH_UP_SECTION_TITLE}
        description={CATCH_UP_SECTION_DESCRIPTION}
        hasBigImage
      >
        <StyledContactUsButton
          to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
        >
          {CONTACT_US_BUTTON_TEXT}
        </StyledContactUsButton>
      </CrawlableSecondCatchUpSection>
    </StyledMarginWrapper>
  )
}

export default CatchUpSection
